import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { Observable, Observer } from 'rxjs';
import { map } from 'rxjs/operators';
import { Hospitals, IncidentsStatuses, Relationship } from '../store/incidents/incidents.state';
import { PatientsList, RegisterPatientModel } from '../incidents/incidents.model';
import { PatientStatuses } from '../patients/patients.component';
import { getDataFromLocalStorage, removeEmptyObjectValues } from '../../shared/helpers/other';
import { UserModel } from '../../models/user.model';
import { List } from '../../shared/models';
import { IncidentOfList } from '../../shared/models/incidents';

@Injectable({
  providedIn: 'root',
})
export class IncidentsService {
  constructor(
    private http: HttpClient,
    private datePipe: DatePipe,
    @Inject('API_URL') private apiUrl: string,
  ) {}

  getGroupedIncidents(filters?): Observable<List<IncidentOfList>> {
    return new Observable((observer: Observer<any>) => {
      const newFilters = filters ? filters : {};
      this.http
        .get<any>(`${this.apiUrl}/incidents/incidents`, {
          params: removeEmptyObjectValues(newFilters),
          observe: 'response',
        })
        .subscribe((success: HttpResponse<any>) => {
          observer.next({
            data: success.body,
            total: success.headers.get('X-Items-Count'),
          });
          observer.complete();
        });
    });
  }

  getNewIncidents(filters?): Observable<List<IncidentOfList>> {
    return new Observable((observer: Observer<any>) => {
      const newFilters = filters ? filters : {};
      this.http
        .get<any>(`${this.apiUrl}/incidents/incidents/new`, {
          params: removeEmptyObjectValues(newFilters),
          observe: 'response',
        })
        .subscribe((success: HttpResponse<any>) => {
          observer.next({
            data: success.body,
            total: success.headers.get('X-Items-Count'),
          });
          observer.complete();
        });
    });
  }

  getAllIncidents(filters?): Observable<List<IncidentOfList>> {
    return new Observable((observer: Observer<any>) => {
      const newFilters = filters ? filters : {};
      this.http
        .get<any>(`${this.apiUrl}/incidents/incidents/all`, {
          params: removeEmptyObjectValues(newFilters),
          observe: 'response',
        })
        .subscribe((success: HttpResponse<any>) => {
          observer.next({
            data: success.body,
            total: success.headers.get('X-Items-Count'),
          });
          observer.complete();
        });
    });
  }

  getIncidentStatuses(): Observable<IncidentsStatuses[]> {
    return this.http.get<IncidentsStatuses[]>(`${this.apiUrl}/incidents/statuses`).pipe(
      map((statuses: IncidentsStatuses[]) => {
        return statuses.map((status: IncidentsStatuses) => ({ ...status, name: status.title }));
      }),
    );
  }

  getIncidentTypes(): Observable<IncidentsStatuses[]> {
    const urlParams: URLSearchParams = new URLSearchParams(window.location.search);
    const user: UserModel = getDataFromLocalStorage('user');
    const token: string = (user || {}).token || urlParams.get('jwt');
    const headers: HttpHeaders = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    return this.http.get<IncidentsStatuses[]>(`${this.apiUrl}/incidents/incident-types`, { headers }).pipe(
      map((types: IncidentsStatuses[]) => {
        return types.map((type: IncidentsStatuses) => ({ ...type, name: type.title, disabled: false }));
      }),
    );
  }

  deleteIncident(ids): Observable<void> {
    const params = {
      'ids[]': ids,
    };
    return this.http.delete<void>(`${this.apiUrl}/incidents/incidents`, {
      params: removeEmptyObjectValues(params),
    });
  }

  getPatientsList(filters: any = {}): Observable<PatientsList[]> {
    return this.http.get<PatientsList[]>(`${this.apiUrl}/incidents/patients`, {
      params: filters,
    });
  }

  getIncidentHospitals(filters: any = {}): Observable<Hospitals[]> {
    const obj = {
      search: filters.hospitalTitle ? filters.hospitalTitle : '',
    };
    return this.http.get<Hospitals[]>(`${this.apiUrl}/incidents/hospitals`, {
      params: obj,
    });
  }

  registerPatient(patientId: number | string): Observable<RegisterPatientModel> {
    return this.http.post<RegisterPatientModel>(`${this.apiUrl}/incidents/incidents`, { patientId });
  }

  editPatient(patientId: string, formData: any): Observable<any> {
    return this.http.put<RegisterPatientModel>(`${this.apiUrl}/incidents/incidents/${patientId}`, {
      ...formData,
    });
  }

  getIncidentRelationship(): Observable<Relationship[]> {
    const urlParams: URLSearchParams = new URLSearchParams(window.location.search);
    const user: UserModel = getDataFromLocalStorage('user');
    const token: string = (user || {}).token || urlParams.get('jwt');
    const headers: HttpHeaders = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    return this.http.get<Relationship[]>(`${this.apiUrl}/incidents/reporter-relationships`, { headers });
  }

  setIncident(id: string): Observable<any> {
    return this.http.post(`${this.apiUrl}/incidents/incidents/${id}/status/new`, {});
  }

  getIncidentByUid(id: string): Observable<any> {
    return this.http.get(`${this.apiUrl}/incidents/incidents/${id}`);
  }

  updateIncidentStatus(data: any): Observable<any> {
    const payload = data.force ? { force: data.force } : {};
    return this.http.post(`${this.apiUrl}/incidents/incidents/${data.id}/status/${data.value}`, payload);
  }

  getCoordinators(search: string = ''): Observable<any> {
    const urlParams: URLSearchParams = new URLSearchParams(window.location.search);
    const user: UserModel = getDataFromLocalStorage('user');
    const token: string = (user || {}).token || urlParams.get('jwt');
    const headers: HttpHeaders = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    return this.http.get(`${this.apiUrl}/incidents/coordinators`, { headers, params: { search } });
  }

  getListOfDiseaseTypes(): Observable<any> {
    return this.http.get(`${this.apiUrl}/incidents/disease-types`, {});
  }

  downloadIncidentPdf(id: string): Observable<any> {
    return this.http.get(`${this.apiUrl}/incidents/incidents/${id}/pdf`, {});
  }

  getPatientStatuses(): Observable<PatientStatuses[]> {
    return this.http.get<PatientStatuses[]>(`${this.apiUrl}/patients/patient-statuses`);
  }

  setIncidentNotesById(id: string, text: string, reason: number): Observable<any> {
    return this.http.post(`${this.apiUrl}/incidents/incidents/${id}/notes`, { text, reason: reason ? +reason : null });
  }

  getIncidentNotesById(id: string, filters: any): Observable<any> {
    return new Observable((observer: any) => {
      this.http
        .get(`${this.apiUrl}/incidents/incidents/${id}/notes`, {
          params: removeEmptyObjectValues(filters),
          observe: 'response',
        })
        .subscribe({
          next: (success: HttpResponse<any[]>) => {
            observer.next({
              data: success.body,
              total: +success.headers.get('X-Items-Count'),
            });
            observer.complete();
          },
          error: (err: any) => {
            observer.error(err);
          },
        });
    });
  }

  updateIncidentNoteById(id: string, uuid: string, text: string, reason: number): Observable<any> {
    return this.http.put(`${this.apiUrl}/incidents/incidents/${id}/notes/${uuid}`, { text, reason });
  }

  deleteIncidentNotesById(id: string, uuid: string): Observable<void> {
    return this.http.delete<void>(`${this.apiUrl}/incidents/incidents/${id}/notes/${uuid}`);
  }

  getUserListToAssign(search: string, params: number): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/v2/incidents/assign/users`, { params: <any>{ search, forFilter: params } });
  }

  setAssignUserToIncidents(id: string[], assignIds: string[]): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/v2/incidents/assign`, { assignIds, ids: id });
  }

  deleteAssignedUser(employeeId: string, userId: string): Observable<void> {
    return this.http.delete<void>(`${this.apiUrl}/v2/incidents/${employeeId}/assign/${userId}`);
  }

  getIncidentsByPatientId(id: string, filters): Observable<List<IncidentOfList>> {
    return new Observable((observer: any) => {
      this.http
        .get(`${this.apiUrl}/incidents/patients/${id}`, {
          params: removeEmptyObjectValues(filters),
          observe: 'response',
        })
        .subscribe({
          next: (success: HttpResponse<any[]>) => {
            observer.next({
              data: success.body,
              total: +success.headers.get('X-Items-Count'),
            });
            observer.complete();
          },
          error: (err: any) => {
            observer.error(err);
          },
        });
    });
  }
}
