import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { PatientFilter } from '../store/patients/patients-list/patients.state';
import { removeEmptyObjectValues } from '../../shared/helpers/other';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PatientsService {
  url: string;
  urlV2: string;

  constructor(
    private http: HttpClient,
    @Inject('API_URL') private apiUrl: string,
  ) {
    this.url = `${this.apiUrl}/patients`;
    this.urlV2 = `${this.apiUrl}/v2/patients`;
  }

  getAll(params: any): Observable<any> {
    return this.http.get(this.url, { params, observe: 'response' }).pipe(
      map(res => ({
        data: res.body,
        total: res.headers.get('X-Items-Count'),
      })),
    );
  }

  getPatientsFilter(params: { search: string }): Observable<PatientFilter[]> {
    return this.http.get<PatientFilter[]>(`${this.urlV2}/filter`, { params });
  }

  getById(id: string): Observable<any> {
    return this.http.get(`${this.url}/${id}`);
  }

  getIncidents(id: string, params: any): Observable<any> {
    return this.http.get(`${this.url}/${id}/incidents`, { params: this.parseParams(params), observe: 'response' }).pipe(
      map(success => ({
        list: success.body,
        total: success.headers.get('X-Items-Count'),
      })),
    );
  }

  getAdmissions(id: string, params: any): Observable<any> {
    return this.http.get(`${this.url}/${id}/admissions`, { params: this.parseParams(params), observe: 'response' }).pipe(
      map(success => ({
        list: (<any[]>success.body).map(item => ({ ...item, signed: item.status.title === 'Signed' })),
        total: success.headers.get('X-Items-Count'),
      })),
    );
  }

  getAttachmets(id: string, params: any): Observable<any> {
    return this.http.get(`${this.url}/${id}/attachments`, { params: this.parseParams(params), observe: 'response' }).pipe(
      map(success => ({
        list: success.body,
        total: success.headers.get('X-Items-Count'),
      })),
    );
  }

  postAttachment(body: any, id: string): Observable<any> {
    const formData = new FormData();
    Object.keys(body || {}).forEach(key => formData.append(key, body[key]));
    return this.http.post(`${this.url}/${id}/attachments`, formData);
  }

  updatePatientAttachment(id: string, attachmentId: string, body: any): Observable<any> {
    return this.http.put(`${this.url}/${id}/attachments/${attachmentId}`, body);
  }

  deletePacientAttachment(id: string, attachmentId: string): Observable<void> {
    return this.http.delete<void>(`${this.url}/${id}/attachments/${attachmentId}`);
  }

  getPatientAttachmentLink(id: string, attachmentIds: string | string[]): Observable<any> {
    if (Array.isArray(attachmentIds)) {
      const params = { 'ids[]': attachmentIds };
      return this.http.get(`${this.url}/${id}/attachments/multiple`, {
        params: removeEmptyObjectValues(params),
      });
    } else {
      return this.http.get(`${this.url}/${id}/attachments/${attachmentIds}`);
    }
  }

  addPacientAttachmentComment(id: string, attachmentId: string, comment: string): Observable<any> {
    return this.http.post(`${this.url}/${id}/attachments/${attachmentId}/comment`, { comment });
  }

  syncAttachment(id: string, attachmentId: string): Observable<any> {
    return this.http.post(`${this.url}/${id}/attachments/${attachmentId}/hha-sync`, {});
  }

  setPatients(patient): Observable<any> {
    return this.http.post(`${this.url}`, patient);
  }

  getUserListToAssign(search: string, params: number): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/v2/patients/assign/users`, { params: <any>{ search, forFilter: params } });
  }

  setAssignUserToPatient(uuid: string[], assignIds: string[]): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/v2/patients/assign`, { assignIds, ids: uuid });
  }

  deleteAssignedUser(patientId: string, userId: string): Observable<void> {
    return this.http.delete<void>(`${this.apiUrl}/v2/patients/${patientId}/assign/${userId}`);
  }

  private parseParams(params: any): any {
    let newParams = { ...params };
    Object.keys(newParams || {}).forEach((key: string) => {
      if (Array.isArray(newParams[key])) {
        newParams = {
          ...newParams,
          [key + '[]']: newParams[key],
        };
        delete newParams[key];
      }
      if (newParams[key] === null) {
        newParams[key] = '';
      }
    });
    if (params.query) {
      newParams['search'] = params.query;
    }
    delete newParams.query;
    return removeEmptyObjectValues(newParams);
  }
}
